<template>
    <section id="posts">
        <base-card class="mx-4 px-2">
            <!--<template v-for="(post, i) in posts">
                <v-divider
                        v-if="i !== 0"
                        :key="`divider-${i}`"
                        class="mb-5"
                />
                <h2
                        :key="`title-${i}`"
                        class="display-2 font-weight-light secondary&#45;&#45;text mb-4"
                        v-text="post.post_title"
                />
                <post
                        :key="i"
                        :value="post"
                        dense
                />

                <div
                        :key="`more-${i}`"
                        class="mb-5"
                >
                    <v-btn
                            :to="`/news/${post.post_name}`"
                            color="secondary"
                            class="font-weight-light mt-4"
                    >
                       {{$t('news.readMore')}}
                    </v-btn>
                </div>
            </template>-->
            <feed :articles="posts">
                <slot />
            </feed>
        </base-card>
    </section>
</template>

<script>
    export default {
        components: {
            Feed: () => import('@/components/blog/Feed')
        },
        props:{
            posts: {
                type:Array,
                default: () => ([])
            }
        }
    }
</script>

<style>
    .on-hover {
        background-color: grey;
    }
</style>
